import React, { type SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import { repeat, update } from 'ramda'
import { Box, CloseButton, Flex, Button } from 'pcln-design-system'
import { Plus as PlusIcon, Minus as MinusIcon } from 'pcln-icons'
import { AirPricedCalendar } from '@pcln/fly-date-picker'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import DateField from '@/components/DateField'
import ShadowEffect from '@/components/ShadowEffect'
import TypeAhead from '@/components/TypeAhead'
import { PopoverContainer } from '@/components/Popover'
import { getDateRange } from '@/shared-utils/date-helper'
import {
  getDateFieldErrorMessage,
  getDateFieldBorderColors
} from '@/shared-utils/error-helpers'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { FlightRecordType, FlightFormStateType, FlightDateType } from '../types'
import {
  setFlights,
  removeFlight,
  addFlight,
  getPrevDate,
  flightsStartLocationSearchKey,
  flightsEndLocationSearchKey,
  flightsDateRangeKey
} from './utils'
import { MULTI_DESTINATION } from '../constants'

const Close = styled(CloseButton)`
  padding: 0 12px;
  height: 48px;
`

type MultiDestinationProps = {
  flights: Partial<FlightRecordType>[]
  errors: FieldErrors<FlightFormStateType>
  setValue: UseFormSetValue<FlightFormStateType>
  isMobile: boolean
}

export default function MultiDestination({
  flights,
  errors,
  setValue,
  isMobile
}: MultiDestinationProps) {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean[]>(
    repeat(false, 4)
  )

  const typeaheadWidth = [1, null, 1 / 2, null, 1 / 3]
  const startLocationPlaceholder = 'Departing from?'
  const endLocationPlaceholder = 'Going to?'

  const datePickerLabel = 'Departing'
  return (
    <>
      {flights.slice(1).map((flight, index) => {
        const flightIndex = index + 1
        const startLocationSearchKey =
          flightsStartLocationSearchKey(flightIndex)
        const endLocationSearchKey = flightsEndLocationSearchKey(flightIndex)
        const dateRangeKey = flightsDateRangeKey(flightIndex)
        return (
          <Flex
            key={flightIndex}
            as="section"
            aria-label={`Flight details for Leg ${flightIndex + 1}`}
            flexWrap="wrap"
            width={1}
          >
            <Box width={typeaheadWidth} px={2} py={[0, 2]}>
              <ShadowEffect enabled>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <TypeAhead
                    searchProduct="flights"
                    placeholder={startLocationPlaceholder}
                    label={startLocationPlaceholder}
                    searchKey={startLocationSearchKey}
                    errors={errors}
                    defaultSelectedItem={flight.startLocation ?? null}
                    disableShadowState={disableShadowState}
                    onItemSelect={(item: LOCATION_SEARCH_TYPE) => {
                      setFlights(
                        flights,
                        {
                          startLocation: item
                        },
                        setValue,
                        flightIndex
                      )
                    }}
                  />
                )}
              </ShadowEffect>
            </Box>
            <Box width={typeaheadWidth} px={2} py={[0, 2]}>
              <ShadowEffect enabled>
                {({
                  disableShadowState
                }: {
                  disableShadowState: () => void
                }) => (
                  <TypeAhead
                    searchProduct="flights"
                    placeholder={endLocationPlaceholder}
                    label={endLocationPlaceholder}
                    searchKey={endLocationSearchKey}
                    errors={errors}
                    defaultSelectedItem={flight.endLocation ?? null}
                    disableShadowState={disableShadowState}
                    onItemSelect={(item: LOCATION_SEARCH_TYPE) => {
                      setFlights(
                        flights,
                        {
                          endLocation: item
                        },
                        setValue,
                        flightIndex
                      )
                    }}
                  />
                )}
              </ShadowEffect>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="stretch"
              width={[1, null, null, null, 1 / 3]}
              px={2}
              py={[0, 2]}
            >
              <ShadowEffect>
                {({
                  disableShadowState
                }: {
                  // eslint-disable-next-line react/no-unused-prop-types
                  disableShadowState: () => void
                }) => (
                  <PopoverContainer
                    key="dates"
                    data-calendar
                    open={isCalendarOpen[index]}
                    position="right"
                    onDismiss={() => {
                      setIsCalendarOpen(update(index, false, isCalendarOpen))
                      disableShadowState()
                    }}
                  >
                    <Box>
                      <DateField
                        m={0}
                        width={1 / 2}
                        name={dateRangeKey}
                        label={datePickerLabel}
                        value={getDateRange(
                          {
                            departureDate: flight.startDate ?? null,
                            arrivalDate: flight.endDate ?? null
                          },
                          true
                        )}
                        onFocus={() => {
                          setIsCalendarOpen(update(index, true, isCalendarOpen))
                          // analytics call for handleCalendarOnFocus(`datefield ${i}`)
                        }}
                        error={getDateFieldErrorMessage(errors, flightIndex)}
                        hideTooltip={
                          isCalendarOpen[index] || !!flight.startDate
                        }
                        color={getDateFieldBorderColors(errors, flightIndex)}
                        aria-expanded={isCalendarOpen[index]}
                      />
                    </Box>
                    <CalendarWrapperBox>
                      <AirPricedCalendar
                        use2024DesignSeti
                        roundedCorners
                        monthWidthPx={300}
                        onChange={(
                          _e: React.ChangeEvent,
                          dates: FlightDateType
                        ) => {
                          const { startDate = '' } = dates
                          const dateObj = { startDate }
                          setFlights(flights, dateObj, setValue, flightIndex)
                          disableShadowState()
                          setIsCalendarOpen(
                            update(index, false, isCalendarOpen)
                          )
                        }}
                        minDate={getPrevDate(flights, flightIndex)}
                        startDate={flight.startDate}
                        selectTwoDates={false}
                        showHoveredDates={false}
                        allowSameDay={false}
                        flightDetails={{
                          cabinClass: 'Economy',
                          flights,
                          tripType: MULTI_DESTINATION
                        }}
                        variantName="EXPRESS"
                        useDefaultFooter
                        onDismiss={() => {
                          disableShadowState()
                          setIsCalendarOpen(
                            update(index, false, isCalendarOpen)
                          )
                        }}
                      />
                    </CalendarWrapperBox>
                  </PopoverContainer>
                )}
              </ShadowEffect>
              {flightIndex > 0 && !isMobile && (
                <Close
                  animate={false}
                  aria-label="remove flight"
                  ml={2}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    removeFlight(flights, flightIndex, setValue)
                  }}
                  title="Remove flight"
                />
              )}
            </Flex>
          </Flex>
        )
      })}
      <Box p={2} width={1} mb={[2, 0]}>
        {isMobile ? (
          <Flex mx={-1} alignItems="center" justifyContent="stretch">
            <Button
              variation="outline"
              aria-label="Add another flight"
              disabled={flights?.length > 4}
              mx={1}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                addFlight(flights, setValue)
              }}
              size="small"
              width={1 / 2}
            >
              <PlusIcon size={24} />
            </Button>
            <Button
              variation="outline"
              aria-label="remove flight"
              disabled={flights?.length < 2}
              mx={1}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                removeFlight(flights, flights.length - 1, setValue)
              }}
              size="small"
              width={1 / 2}
            >
              <MinusIcon size={24} />
            </Button>
          </Flex>
        ) : (
          <Button
            variation="outline"
            disabled={flights?.length > 4}
            onClick={(e: SyntheticEvent) => {
              e.preventDefault()
              addFlight(flights, setValue)
            }}
            pl="12px"
            size="medium"
          >
            <Flex alignItems="center">
              <PlusIcon size={24} />
              Add another flight
            </Flex>
          </Button>
        )}
      </Box>
    </>
  )
}
