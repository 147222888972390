import { CABIN_CLASS } from '@/components/Flights/constants'
import { CABIN_CLASS_TYPE } from '@/components/Flights/types'
import { ModifyFlightDetails, RebookFlightDetails } from '@/types'

export function isValidUserActionType(
  userActionType?: string | string[]
): userActionType is 'REBOOK' | 'MODIFY' {
  return userActionType === 'MODIFY' || userActionType === 'REBOOK'
}

export function isModifyFlightDetails(
  details: RebookFlightDetails | ModifyFlightDetails
): details is ModifyFlightDetails {
  return 'destinationCity' in details
}

export function isRebookFlightDetails(
  details: RebookFlightDetails | ModifyFlightDetails
): details is RebookFlightDetails {
  return 'creditAmount' in details
}

export function mapCabinClass(cabinClassCode: string) {
  return (Object.keys(CABIN_CLASS).find(
    key => CABIN_CLASS[key as CABIN_CLASS_TYPE] === cabinClassCode
  ) ?? 'Economy') as CABIN_CLASS_TYPE
}
