type tripTypes = 'ONE_WAY' | 'ROUND_TRIP' | 'MULTI_DESTINATION'

export type AirportDetails = {
  city?: string
  code: string
  name: string
  state?: string
  country: string
}

export const FLY_CABIN_CLASS = {
  BUSINESS: 'BUS',
  ECONOMY: 'ECO',
  FIRST: 'FST',
  PREMIUM_ECONOMY: 'PEC'
} as const

export type FLY_CABIN_CLASS_TYPE = keyof typeof FLY_CABIN_CLASS

type OriginDestinationData = {
  originCityData: Readonly<{
    cityID: string
    cityName: string
  }>
  destinationCityData: Readonly<{
    cityID: string
    cityName: string
  }>
  originAirportData: Readonly<{
    airportCode: string
    airportName: string
  }>
  destinationAirportData: Readonly<{
    airportCode: string
    airportName: string
  }>
}

type FlyOriginDestinationData = {
  originDestinationData: Readonly<OriginDestinationData>
  startDate: string | null
}

type StayDestinationData = {
  destinationCityData: Readonly<{
    cityID: string
    cityName: string
  }>
  startDate: string | null
  endDate: string | null
}

// Clean up 'HP_RT_SEARCHES' : remove infantCount if experiment wins
export type FlyTravelersCountType = Readonly<{
  adultCount: number
  childCount: number
  infantCount: number
}>

export type FlightsTravelersCountType = Readonly<{
  adultCount: number
  childCount: number
}>

export type FlySearchData<T = FlyOriginDestinationData> = {
  productType: 'FLY'
  flyCabinClass: FLY_CABIN_CLASS_TYPE
  flyTripType: tripTypes
  flyTravelersCount: FlyTravelersCountType
  flyOriginDestinationData: readonly [Readonly<T>, ...ReadonlyArray<T>]
}

export type RecentFlightSearches = {
  productType: 'FLY'
  cabinClass: FLY_CABIN_CLASS_TYPE
  tripType: string
  numberOfAdults: number
  numberOfChildren: number
  destinationAirportInfo: AirportDetails
  originAirportInfo: AirportDetails
  listingsUrl: string
  travelStartDate: string
  travelEndDate: string
}

type DriveSearchData = {
  productType: 'DRIVE'
  driveTripType: tripTypes
  driveStartDate: string | null
  driveEndDate: string | null
  drivePickupTime: string
  driveDropOffTime: string
  driveOriginDestinationData: Readonly<OriginDestinationData>
}

export type RecentRCSearches = {
  productType: 'DRIVE'
  pickupDateTime: string
  returnDateTime: string
  pickupLocationId: string
  pickupLocationName: string
  returnLocationId: string
  returnLocationName: string
  listingsUrl: string
}

type StaySearchData<T = StayDestinationData> = {
  productType: 'STAY'
  stayTravelersCount: Readonly<{
    adultCount: number
    childCount: number
    infantCount: number
  }>
  stayRoomCount: number
  stayDestinationData: readonly [Readonly<T>]
}

export type RecentHotelSearches = {
  productType: 'STAY'
  numberOfAdults: number
  numberOfChildren: number
  cityId: string
  cityName: string
  listingsUrl: string
  checkInDate: string
  checkOutDate: string
}

export type SearchProductData = Readonly<
  FlySearchData | DriveSearchData | StaySearchData
>

type NonNullableReadonlyFields<T, K extends keyof T> = T & {
  readonly [P in K]: NonNullable<T[K]>
}

type MakeStartDateNonNullableReadonly<T extends { startDate: string | null }> =
  NonNullableReadonlyFields<T, 'startDate'>

type StayDestinationDataFiltered =
  MakeStartDateNonNullableReadonly<StayDestinationData>

export type FlyOriginDestinationDataFiltered =
  MakeStartDateNonNullableReadonly<FlyOriginDestinationData>

type DriveSearchDataFiltered = NonNullableReadonlyFields<
  DriveSearchData,
  'driveStartDate'
>

export type SearchProductDataFiltered = Readonly<
  | StaySearchData<StayDestinationDataFiltered>
  | FlySearchData<FlyOriginDestinationDataFiltered>
  | DriveSearchDataFiltered
>

export type RecentTripSearches = Readonly<
  RecentHotelSearches | RecentFlightSearches | RecentRCSearches
>

export type PackageTypeCode = 'A' | 'AH' | 'AC' | 'AHC' | 'ACH'

export type CabinClassType = 'BUS' | 'ECO' | 'FST' | 'PEC'

export type PackageSearchParamsType = {
  cabinClass: CabinClassType
  destinationCityCode: string
  endDateShortFormat?: string
  flyTravelersCount: FlyTravelersCountType
  originCityCode: string
  startDateShortFormat: string
}

export type FlyRecentSearchData = {
  subheadline: string
  startDateLongFormat: string
  startDateShortFormat: string
  endDateLongFormat?: string
  endDateShortFormat?: string
  originState: string
  originCity: string
  originCityCode: string
  destinationState: string
  destinationCity: string
  destinationCityCode: string
  flyTravelersCount: FlyTravelersCountType
  cabinClass: CabinClassType
}

export type FlightsRecentSearchData = {
  subheadline: string
  startDateLongFormat: string
  startDateShortFormat: string
  endDateLongFormat?: string
  endDateShortFormat?: string
  originState: string
  originCity: string
  originCityCode: string
  destinationState: string
  destinationCity: string
  destinationCityCode: string
  flyTravelersCount: FlightsTravelersCountType
  cabinClass: CabinClassType
  listingsUrl: string
}

export type MostRecentFlightSearches =
  | ReadonlyArray<FlySearchData<FlyOriginDestinationDataFiltered>>
  | ReadonlyArray<RecentFlightSearches>

type AbandonedHotelSelection = {
  headline: string
  subheadline: string
  hotelName?: string
  neighborhoodName?: string
  numReviews?: number
  numStars?: number
  overallRating?: number
  checkInDate: string
  checkOutDate: string
  numAdults: number
  numRooms: number
  imageSrc?: string
  linkUrl: string
  genericListingsUrl: string
  hotelDealType: 'AbandonedPriceBreakersHotel' | 'SopqHotel' | 'RtlHotel'
  description?: string
  location?: string
  productType: 'hotels'
}

type AbandonedCarSelection = {
  headline: string
  expressDealText: string
  pickupLocation: string
  isDropOffAtDifferentLocation: boolean
  returnLocation: string
  specifyOffAirport: boolean
  pickUpDate: string
  dropOffDate: string
  numAdults: string
  linkUrl: string
  genericListingsUrl: string
  numReviews?: string
  overallRating?: string
  imageSrc: string
  rcDealType: string
  productType: 'cars'
  brandImg?: string
}

export type AbandonedSelection = AbandonedHotelSelection | AbandonedCarSelection

export type AbandonedPriceBreakersHotel = {
  __typename: 'AbandonedPriceBreakersHotel'
  pricebreakerDescription: string
  pricebreakerLocation: string
}

export type RTLHotel = {
  __typename: 'RtlHotel'
  thumbnail?: {
    source: string
  }
  name?: string
  starRating?: string
  reviewSummaryWithEntityInput?: {
    totalReviews?: number
    scoreBreakdown?: {
      overall?: string
    }
  }
  neighborhood?: {
    name?: string
  }
}

export type SopqHotel = {
  __typename: 'SopqHotel'
  name?: string
  starRating?: string
  neighborhood?: {
    name?: string
  }
  location?: {
    name?: string
  }
}

export type AbandonedHotelSelectionApiResponse = {
  hotelURL: string
  genericListingsUrl: string
  checkInDate: string
  checkOutDate: string
  numberOfAdults: number
  numberOfRooms: number
  isBooked: boolean
  hotelInfo: RTLHotel | SopqHotel | AbandonedPriceBreakersHotel
}

export type AbandonedCarSelectionApiResponse = {
  carURL: string
  genericListingsUrl: string
  averageRating?: number
  carExample: string
  carType: string
  imageURL: string
  isDropOffAtDifferentLocation: boolean
  isOffAirportPickup: boolean
  numberOfReviews: number
  passengerCapacity: number
  pickupDateTime: string
  pickupLocation: string
  returnDateTime: string
  returnLocation: string
  retailTypeCarCompanyDetails: {
    brandImageURL: string
  }
  isBooked: boolean
}

export type AbandonedSelectionApiResponse =
  | AbandonedHotelSelectionApiResponse
  | AbandonedCarSelectionApiResponse

export type PriceWatchData = {
  cabinClass: FLY_CABIN_CLASS_TYPE
  dealPrice: string
  departDate: string
  destAbbreviation: string
  destCityId: string
  destCityName: string
  destStateName: string
  lastNotifiedPrice: string
  originAbbreviation: string
  originCityId: string
  originCityName: string
  originStateName: string
  returnDate: string
  savings: string
  savingsPercentage: string
  tripType: string
}

export type PriceWatchDataWithFlags = PriceWatchData & {
  productType: 'FLY'
  isPriceWatch: boolean
}
