import React from 'react'
import { Box, Flex, Label, Checkbox, Text } from 'pcln-design-system'
import { Discount } from 'pcln-icons'
import styled from 'styled-components'
import useSeti from '@/hooks/useSeti'
import Variant1 from './Variant1'
import Variant2 from './Variant2'
import { HotelOption, CarOption, FlightOption } from './utils'

type ProductOption = typeof HotelOption | typeof CarOption | typeof FlightOption

type OptionLabel =
  | typeof CarOption.label
  | typeof HotelOption.label
  | typeof FlightOption.label

type BundleAndSaveProps = {
  productOptions: [ProductOption, ...ProductOption[]]
  tripType: string
  label: string
  onSelectionChange: (
    updatedTripType: string,
    checked: boolean,
    label: OptionLabel
  ) => void
  color?: string
}

const BundleAndSaveText = styled(Text)`
  white-space: nowrap;
`

export default function BundleAndSave({
  productOptions,
  tripType,
  label,
  color = 'success.dark',
  onSelectionChange
}: BundleAndSaveProps) {
  const isBundleAndSaveCheckBox = useSeti('PKG_FE_HP_CHECKBOXES', true)
  const isDefault =
    isBundleAndSaveCheckBox === 'DEFAULT' ||
    isBundleAndSaveCheckBox === 'CONTROL'
  const isVariant1 = isBundleAndSaveCheckBox === 'VARIANT_1'
  const isVariant2 = isBundleAndSaveCheckBox === 'VARIANT_2'

  const crossSellLabel = isDefault ? label : label.replace('+', '&')
  function getUpdatedTripType(
    e: React.ChangeEvent<HTMLInputElement>,
    option: ProductOption
  ) {
    return e.target.checked
      ? `${tripType}:${option.type}`
      : tripType.replace(`:${option.type}`, '')
  }

  return (
    <>
      {isDefault && (
        <Flex
          p={1}
          borderRadius="lg"
          height="100%"
          color="highlight.light"
          alignItems="center"
        >
          <Flex>
            <Discount m={1} mr={2} color={color} />
            <BundleAndSaveText
              pr={[1, 2]}
              color={color}
              bold
              fontSize={[0, null, null, null, 1]}
              m="auto"
              ml={0}
            >
              {crossSellLabel}
            </BundleAndSaveText>
          </Flex>
          <Flex flexWrap="wrap" alignItems="center">
            {productOptions.map(option => {
              const checked = tripType.includes(option.type)
              return (
                <Box key={option.name} mt="2px" pr={[1, 2]}>
                  <Label
                    fontSize={0}
                    htmlFor={option.id}
                    color="text"
                    fontWeight="normal"
                  >
                    <Checkbox
                      aria-label={option.label}
                      id={option.id}
                      data-testid={option.id}
                      name={option.name}
                      value={option.type}
                      checked={checked}
                      unselectedColor="text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onSelectionChange(
                          getUpdatedTripType(e, option),
                          e.target.checked,
                          option.label
                        )
                      }}
                    />
                    {` ${option.label} `}
                  </Label>
                </Box>
              )
            })}
          </Flex>
        </Flex>
      )}
      {isVariant1 && (
        <Variant1
          productOptions={productOptions}
          tripType={tripType}
          label={crossSellLabel}
          color={color}
          onSelectionChange={onSelectionChange}
        />
      )}
      {isVariant2 && (
        <Variant2
          productOptions={productOptions}
          tripType={tripType}
          label={crossSellLabel}
          color={color}
          onSelectionChange={onSelectionChange}
        />
      )}
    </>
  )
}
