import React, { useState, useRef, useEffect, useCallback } from 'react'
import { isEmpty } from 'ramda'
import styled from 'styled-components'
import { Box, Flex, FormField, Label, Select, Text } from 'pcln-design-system'
import { useRouter } from 'next/router'
import { Calendar } from '@pcln/date-picker'
import { AirPricedCalendar } from '@pcln/fly-date-picker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import useBootstrapData from '@/hooks/useBootstrapData'
import DateField from '@/components/DateField'
import ShadowEffect from '@/components/ShadowEffect'
import TypeAhead from '@/components/TypeAhead'
import { PopoverContainer } from '@/components/Popover'
import { FLIGHTS_DISCLAIMER } from '@/components/TravelerSelection/constants'
import TravelerSelection from '@/components/TravelerSelection/TravelerSelection'
import useTabGroup from '@/hooks/useTabGroup'
import SearchFormButton from '@/components/SearchFormButton'
import { getDateRange } from '@/shared-utils/date-helper'
import {
  getDateFieldBorderColors,
  getDateFieldErrorMessage
} from '@/shared-utils/error-helpers'
import { LOCATION_SEARCH_TYPE, QueryString } from '@/types'
import {
  ANALYTICS_CATEGORY_FLIGHTS,
  ANALYTICS_CATEGORY_HOME
} from '@/constants/analytics'
import AmbiguousCityLink from '@/components/AmbiguousCityLink'
import BundleAndSave, {
  HotelOption,
  CarOption
} from '@/components/BundleAndSave'
import { isRebookFlightDetails } from '@/shared-utils/flight-rebook-helpers'
import { fireInfantErrorEvent } from '@/components/TravelerSelection/ga4'
import { type GA4PageNameType } from '@/ga4types'
import MultiDestination from './MultiDestination'
import {
  CABIN_CLASS,
  FLIGHT_TYPE_INFO,
  HOTEL,
  MULTI_DESTINATION,
  ONE_WAY,
  ROUND_TRIP
} from '../constants'
import {
  FlightRecordType,
  FlightFormStateType,
  CABIN_CLASS_TYPE,
  FLIGHT_TYPE_INFO as FLIGHT_TYPE_INFO_TYPE,
  FlightDateType
} from '../types'
import searchFormSchema from './validation'
import FlightTypeRadios from './FlightTypeRadios'
import FlightFormBanner from './FlightFormBanner'
import {
  setFlights,
  getPrevDate,
  isRoundTrip,
  isOneWay,
  isMultiDestination,
  includesStay,
  isPackage,
  fields,
  initialState,
  flightsStartLocationSearchKey,
  flightsEndLocationSearchKey,
  flightsDateRangeKey
} from './utils'
import RebookBanner from './RebookBanner'
import { fireBundleAndSaveEvent } from '../ga4'

const StyledSelect = styled(Select)`
  cursor: pointer;
  height: 56px;
`

type FormType = {
  isRebook?: boolean
  isModify?: boolean
  isLandingPage?: boolean
  onSubmit: (
    pageName: GA4PageNameType,
    data: FlightFormStateType,
    queryParams?: QueryString
  ) => void
  flightTypes?: FLIGHT_TYPE_INFO_TYPE[]
  promoDeal?: boolean
}

export default function Form({
  isRebook = false,
  isModify = false,
  isLandingPage = true,
  onSubmit,
  flightTypes = [
    FLIGHT_TYPE_INFO[ROUND_TRIP],
    FLIGHT_TYPE_INFO[ONE_WAY],
    FLIGHT_TYPE_INFO[MULTI_DESTINATION]
  ],
  promoDeal
}: FormType) {
  const { registerTabElement, focusNextElement } = useTabGroup()

  const { isMobile, prePopData, flightRebookOrModifyDetails } =
    useBootstrapData()

  const {
    flights,
    flightType,
    cabinClass,
    adultCount,
    childrenCount,
    infantCount
  } = prePopData?.flights ?? {}

  const initialValue: FlightFormStateType = {
    ...initialState,
    ...(flights && { flights }),
    ...(flightType && { flightType }),
    ...(cabinClass && { cabinClass }),
    ...(adultCount && { adultCount }),
    ...(childrenCount && { childrenCount }),
    ...(infantCount && { infantCount }),
    ...((isRebook || isModify) &&
      flightRebookOrModifyDetails && {
        cabinClass: flightRebookOrModifyDetails.cabinClass,
        ...flightRebookOrModifyDetails.passengerCount
      })
  }

  const rebookCarrierName = flightRebookOrModifyDetails?.carrierName ?? ''
  const rebookCreditAmount =
    flightRebookOrModifyDetails &&
    isRebookFlightDetails(flightRebookOrModifyDetails)
      ? flightRebookOrModifyDetails.creditAmount
      : ''

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    unregister,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<FlightFormStateType>({
    defaultValues: initialValue,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })
  const {
    adultCount: watchAdultCount,
    cabinClass: watchCabinClass,
    childrenAges: watchChildrenAges,
    childrenCount: watchChildrenCount,
    infantCount: watchInfantCount,
    flightType: watchFlightType,
    flights: watchFlights,
    roomCount: watchRoomCount,
    tripType: watchTripType
  } = watch()

  const { query, pathname } = useRouter()
  const {
    'ambig-origin-city': ambigOriginCity,
    'ambig-dest-city': ambigDestCity
  } = query
  const isHomepage = pathname === '/'
  const ANALYTICS_CATEGORY = isHomepage
    ? ANALYTICS_CATEGORY_HOME
    : ANALYTICS_CATEGORY_FLIGHTS

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [register, unregister])

  useEffect(() => {
    void trigger('roomCount')
  }, [
    trigger,
    watchAdultCount,
    watchChildrenCount,
    watchInfantCount,
    watchTripType,
    watchRoomCount
  ])

  useEffect(() => {
    if (!isEmpty(errors)) {
      void trigger('flights')
    }
  }, [errors, trigger])

  useEffect(() => {
    void trigger('infantCount')
  }, [
    trigger,
    watchFlights,
    watchInfantCount,
    watchAdultCount,
    watchChildrenAges
  ])

  useEffect(() => {
    if (
      isSubmitting &&
      isMobile &&
      typeof window.PCLN?.pclnEventDispatcher?.dispatch === 'function'
    ) {
      const bookingInfo = {
        originCityName: watchFlights[0].startLocation?.cityName,
        originAirportCode: watchFlights[0].startLocation?.id,
        destinationCityName: watchFlights[0].endLocation?.cityName,
        destinationCityAirportCode: watchFlights[0].endLocation?.id,
        departureDate: watchFlights[0].startDate,
        returnDate: watchFlights[0].endDate
      }
      window.PCLN.pclnEventDispatcher.dispatch(
        'FIND_YOUR_FLIGHT_DATA',
        bookingInfo
      )
    }
  }, [isMobile, isSubmitting, watchFlights])

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
  const [departureAirport, setDepartureAirport] =
    useState<LOCATION_SEARCH_TYPE | null>(null)
  const [arrivalAirport, setArrivalAirport] =
    useState<LOCATION_SEARCH_TYPE | null>(null)
  const startLocationPlaceholder = 'Departing from?'
  const endLocationPlaceholder = 'Going to?'
  const isFlightTypeRoundTrip = isRoundTrip(watchFlightType)
  const isFlightTypeOneWay = isOneWay(watchFlightType)
  const isFlightTypeMultiDestination = isMultiDestination(watchFlightType)
  const isFlightTypeIncludeStay = includesStay(watchFlightType)
  const showCabinClass =
    (!isFlightTypeRoundTrip || !isPackage(watchTripType)) && !promoDeal
  const showBundleAndSave = isFlightTypeRoundTrip && !promoDeal && !isModify
  const firstFlight: Partial<FlightRecordType> = watchFlights[0] || {}
  const datePickerLabel = isFlightTypeRoundTrip
    ? 'Departing - Returning'
    : 'Departing'
  const maxDaysHotelToSelect = 30

  const isPricedCalendarActive = !isFlightTypeMultiDestination

  const CalendarComponent = isPricedCalendarActive
    ? AirPricedCalendar
    : Calendar

  const [showOriginMoreCityLink, setShowOriginMoreCityLink] = useState(true)
  const origin = firstFlight?.startLocation?.cityName
  const showOriginDisambiguation =
    !!ambigOriginCity && !!origin && !isHomepage && showOriginMoreCityLink

  const ambiguousOriginElement = useRef<HTMLInputElement>()
  function registerOriginAmbiguousElement(ref: HTMLInputElement): void {
    ambiguousOriginElement.current = ref
  }

  const [showDestMoreCityLink, setShowDestMoreCityLink] = useState(true)
  const destination = firstFlight?.endLocation?.cityName
  const showDestDisambiguation =
    !!ambigDestCity && destination && !isHomepage && showDestMoreCityLink

  const ambiguousDestElement = useRef<HTMLInputElement>()
  function registerDestAmbiguousElement(ref: HTMLInputElement): void {
    ambiguousDestElement.current = ref
  }

  const clarifyAmbiguity = useCallback(
    (
      element: React.MutableRefObject<HTMLInputElement | undefined>,
      flight: Partial<FlightRecordType>,
      locationType: 'endLocation' | 'startLocation'
    ) => {
      const location = flight[locationType]

      return setTimeout(() => {
        if (element.current && location) {
          element.current.focus()
          setValue(
            'flights',
            [
              {
                ...flight,
                [locationType]: {
                  ...location,
                  itemName: location.cityName
                }
              }
            ],
            { shouldDirty: true }
          )
        }
      }, 0)
    },
    [setValue]
  )

  const calculateWidths = () => {
    const baseWidth = showCabinClass ? 1 / 3 : 1 / 2
    const multiDestinationAdjustment = isFlightTypeMultiDestination
      ? baseWidth
      : 1 / 2
    const modifyAdjustment = isModify ? 1 : multiDestinationAdjustment

    return [1, null, showCabinClass ? 1 : 1 / 2, null, modifyAdjustment]
  }

  const typeaheadWidth = [1, null, 1 / 2, null, 1 / 3]
  if (!isFlightTypeMultiDestination) {
    typeaheadWidth[4] = 1 / 2
  }

  const itemSelected = useCallback(
    (item: LOCATION_SEARCH_TYPE) => {
      setFlights(
        watchFlights,
        {
          endLocation: item
        },
        setValue
      )
      setArrivalAirport(item)
      if (item?.cityName !== destination) {
        setShowDestMoreCityLink(false)
      }
      if (!isMobile && !!item) {
        focusNextElement()
      } else if (
        isMobile &&
        item &&
        document.activeElement instanceof HTMLElement
      ) {
        document.activeElement.blur()
      }
    },
    [watchFlights, setValue, destination, isMobile, focusNextElement]
  )

  const skipTypeAheadCustomDisplayName = !!ambigDestCity || !!ambigOriginCity
  const pageName = isLandingPage ? 'landing' : 'homepage'

  if (errors?.infantCount?.message) {
    fireInfantErrorEvent(pageName, 'air')
  }
  return (
    <form
      onSubmit={handleSubmit(values => {
        onSubmit(pageName, { ...values, gaCategory: ANALYTICS_CATEGORY }, query)
      })}
      aria-label={
        promoDeal ? 'flight-express-deal-search-form' : 'flight-search-form'
      }
    >
      <Flex mb={2}>
        <FlightTypeRadios
          flightTypesToDisplay={flightTypes}
          flightType={watchFlightType}
          setValue={setValue}
          flights={watchFlights}
        />
      </Flex>
      <Flex key={0} mx={-2} flexWrap="wrap" pb={isModify ? 0 : 2}>
        <Box width={typeaheadWidth} py={[0, null, 2]} px={2}>
          <ShadowEffect enabled>
            {({ disableShadowState }: { disableShadowState: () => void }) => (
              <TypeAhead
                searchProduct="flights"
                placeholder={startLocationPlaceholder}
                label={startLocationPlaceholder}
                searchKey={flightsStartLocationSearchKey(0)}
                errors={errors}
                defaultSelectedItem={firstFlight?.startLocation ?? null}
                disableShadowState={disableShadowState}
                skipCustomDisplayName={skipTypeAheadCustomDisplayName}
                ref={(elem: HTMLInputElement) => {
                  registerTabElement(elem)
                  if (showOriginDisambiguation) {
                    registerOriginAmbiguousElement(elem)
                  }
                }}
                onItemSelect={(item: LOCATION_SEARCH_TYPE) => {
                  setFlights(
                    watchFlights,
                    {
                      startLocation: item
                    },
                    setValue
                  )
                  setDepartureAirport(item)
                  if (item?.cityName !== origin) {
                    setShowOriginMoreCityLink(false)
                  }
                  if (!isMobile && !!item) {
                    focusNextElement()
                  }
                  if (isMobile && item && document.activeElement) {
                    ;(document.activeElement as HTMLElement).blur()
                  }
                }}
              />
            )}
          </ShadowEffect>
          {showOriginDisambiguation && (
            <AmbiguousCityLink
              city={origin}
              handleCallback={() => {
                clarifyAmbiguity(
                  ambiguousOriginElement,
                  firstFlight,
                  'startLocation'
                )
              }}
            />
          )}
        </Box>
        <Box width={typeaheadWidth} py={[0, null, 2]} px={2}>
          <ShadowEffect enabled>
            {({ disableShadowState }: { disableShadowState: () => void }) => (
              <TypeAhead
                searchProduct="flights"
                placeholder={endLocationPlaceholder}
                label={endLocationPlaceholder}
                searchKey={flightsEndLocationSearchKey(0)}
                errors={errors}
                defaultSelectedItem={firstFlight?.endLocation ?? null}
                disableShadowState={disableShadowState}
                skipCustomDisplayName={skipTypeAheadCustomDisplayName}
                ref={(elem: HTMLInputElement) => {
                  registerTabElement(elem)
                  if (showDestDisambiguation) {
                    registerDestAmbiguousElement(elem)
                  }
                }}
                onItemSelect={item => itemSelected(item)}
              />
            )}
          </ShadowEffect>
          {showDestDisambiguation && (
            <AmbiguousCityLink
              city={destination}
              handleCallback={() => {
                clarifyAmbiguity(
                  ambiguousDestElement,
                  firstFlight,
                  'endLocation'
                )
              }}
            />
          )}
        </Box>
        <Flex
          alignItems="baseline"
          justifyContent="stretch"
          width={
            showCabinClass
              ? [1, null, null, null, 1 / 3]
              : [1, null, 1 / 2, null, 1 / 2]
          }
          py={[0, null, null, 2]}
          px={2}
        >
          <ShadowEffect>
            {({ disableShadowState }: { disableShadowState: () => void }) => (
              <PopoverContainer
                key="dates"
                data-calendar
                open={isCalendarOpen}
                position="left"
                onDismiss={() => {
                  setIsCalendarOpen(false)
                  disableShadowState()
                }}
              >
                <DateField
                  m={0}
                  width={1 / 2}
                  name={flightsDateRangeKey(0)}
                  ref={(elem: HTMLButtonElement) => {
                    registerTabElement(elem)
                  }}
                  label={datePickerLabel}
                  value={getDateRange(
                    {
                      departureDate: firstFlight?.startDate ?? null,
                      arrivalDate: firstFlight?.endDate ?? null
                    },
                    !isFlightTypeRoundTrip
                  )}
                  onFocus={() => {
                    setIsCalendarOpen(true)
                    // analytics call for handleCalendarOnFocus(`datefield ${i}`)
                  }}
                  error={getDateFieldErrorMessage(
                    errors,
                    0,
                    isFlightTypeRoundTrip
                  )}
                  hideTooltip={
                    isCalendarOpen ||
                    ((isFlightTypeOneWay || isFlightTypeMultiDestination) &&
                      !!firstFlight.startDate) ||
                    (isFlightTypeRoundTrip &&
                      !!firstFlight.startDate &&
                      !!firstFlight.endDate)
                  }
                  color={getDateFieldBorderColors(errors, 0)}
                  aria-expanded={isCalendarOpen ? 'true' : 'false'}
                />
                <CalendarWrapperBox
                  boxShadowSize={!isMobile ? 'overlay-lg' : undefined}
                  borderRadius={!isMobile ? '2xl' : undefined}
                >
                  <CalendarComponent
                    use2024DesignSeti
                    isMobile={isMobile}
                    roundedCorners
                    monthWidthPx={350}
                    onChange={(
                      _e: React.ChangeEvent,
                      dates: FlightDateType
                    ) => {
                      const { startDate = '', endDate = '' } = dates
                      const dateObj = {
                        startDate,
                        ...(isFlightTypeRoundTrip && { endDate })
                      }
                      setFlights(watchFlights, dateObj, setValue)
                      if (
                        !isFlightTypeRoundTrip ||
                        (dates.startDate && dates.endDate)
                      ) {
                        disableShadowState()
                        setIsCalendarOpen(false)
                      }
                    }}
                    minDate={getPrevDate(watchFlights, 0)}
                    maxDaysToSelect={
                      watchTripType.includes(HOTEL) && isFlightTypeRoundTrip
                        ? maxDaysHotelToSelect
                        : undefined
                    }
                    startDate={firstFlight.startDate}
                    endDate={
                      isFlightTypeRoundTrip ? firstFlight.endDate : undefined
                    }
                    selectTwoDates={isFlightTypeRoundTrip}
                    showHoveredDates={isFlightTypeRoundTrip}
                    allowSameDay={!isFlightTypeIncludeStay}
                    flightDetails={{
                      cabinClass: watchCabinClass,
                      flights: [
                        {
                          departureAirport,
                          arrivalAirport
                        }
                      ],
                      tripType: watchFlightType
                    }}
                    variantName="EXPRESS"
                    useDefaultFooter={isFlightTypeMultiDestination}
                    onDismiss={() => {
                      disableShadowState()
                      setIsCalendarOpen(false)
                    }}
                    useColorCode={!isFlightTypeMultiDestination}
                    callFlexDatesGraphUrl
                  />
                </CalendarWrapperBox>
              </PopoverContainer>
            )}
          </ShadowEffect>
        </Flex>
        {isFlightTypeMultiDestination && (
          <MultiDestination
            flights={watchFlights}
            setValue={setValue}
            errors={errors}
            isMobile={isMobile}
          />
        )}
        <Box
          width={[1, null, 1 / 2, null, showCabinClass ? 1 / 3 : 1 / 2]}
          py={[0, null, 2]}
          px={2}
        >
          <ShadowEffect enabled alternativeBlurHandling>
            {({ disableShadowState }: { disableShadowState: () => void }) => (
              <TravelerSelection
                disabled={isRebook || isModify}
                adultCount={watchAdultCount}
                childrenAges={watchChildrenAges}
                childrenCount={watchChildrenCount}
                disableShadowState={disableShadowState}
                disclaimer={FLIGHTS_DISCLAIMER}
                infantCount={watchInfantCount}
                onDoneButtonClick={disableShadowState}
                roomCount={watchRoomCount}
                setValue={setValue}
                showChildAgeSelectors={false}
                showInfantMenuItem
                showRoomMenuItem={watchTripType.includes(HOTEL)}
                showSubLabel
                error={
                  errors?.infantCount?.message ||
                  errors?.roomCount?.message ||
                  ''
                }
              />
            )}
          </ShadowEffect>
        </Box>
        {showCabinClass && (
          <Box width={[1, null, 1 / 2, null, 1 / 3]} p={2} mt={[2, null, 0]}>
            <ShadowEffect>
              {(
                { disableShadowState }: { disableShadowState: () => void } // eslint-disable-line react/no-unused-prop-types
              ) => (
                <FormField disabled={isRebook || isModify}>
                  <Label
                    autoHide
                    htmlFor="cabin-class-select"
                    color={isRebook || isModify ? 'text.base' : undefined}
                  >
                    Cabin Class
                  </Label>
                  <StyledSelect
                    m={0}
                    id="cabin-class-select"
                    name="cabinClass"
                    value={watchCabinClass}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setValue(
                        'cabinClass',
                        e.target.value as CABIN_CLASS_TYPE,
                        { shouldDirty: true }
                      )
                      disableShadowState()
                      /**  for GA tracking */
                    }}
                  >
                    {Object.keys(CABIN_CLASS).map(name => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </StyledSelect>
                </FormField>
              )}
            </ShadowEffect>
          </Box>
        )}
        {!isModify && (
          <FlightFormBanner
            isMobile={isMobile}
            isRebook={isRebook}
            bundleAndSaveMobileWidth={calculateWidths()}
            isFlightTypeMultiDestination={isFlightTypeMultiDestination}
            showBundleAndSave={showBundleAndSave}
            bundleAndSave={
              <Box height="56px">
                <BundleAndSave
                  color={isHomepage ? 'highlight.shade' : 'success.dark'}
                  productOptions={[HotelOption, CarOption]}
                  tripType={watchTripType}
                  label="Bundle + Save"
                  onSelectionChange={(updatedTripType, checked) => {
                    setValue('tripType', updatedTripType)
                    if (checked) {
                      fireBundleAndSaveEvent(updatedTripType)
                    }
                  }}
                />
              </Box>
            }
            rebookBanner={
              <RebookBanner
                credit={rebookCreditAmount}
                airline={rebookCarrierName}
              />
            }
          />
        )}
        <Box width={promoDeal ? 1 : calculateWidths()} p={2} pb={0}>
          <SearchFormButton
            buttonText={isModify ? 'Find a New Flight' : 'Find Your Flight'}
            handleClick={() => {
              if (isMobile) {
                window?.PCLN?.pclnEventDispatcher?.dispatch('FIND_YOUR_FLIGHT')
              }
            }}
          />
          {!promoDeal && !isModify && (
            <Text
              textAlign={!isFlightTypeMultiDestination ? 'center' : 'left'}
              mt={2}
              fontSize={0}
            >
              Book a flight with free cancellation for flexibility
            </Text>
          )}
        </Box>
      </Flex>
    </form>
  )
}
