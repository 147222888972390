import React from 'react'
import { Flex, Text } from 'pcln-design-system'
import { Discount } from 'pcln-icons'

type RebookBannerType = {
  credit: string
  airline: string
}

export default function RebookBanner({ credit, airline }: RebookBannerType) {
  return (
    <Flex
      p={1}
      borderRadius="lg"
      height="56px"
      color="highlight.light"
      alignItems="center"
    >
      <Flex>
        <Discount m={1} mr={2} color="success.dark" />
        <Text
          pr={[1, 2, null, 3]}
          color="success.dark"
          bold
          fontSize={[0, null, null, null, 1]}
          m="auto"
          ml={0}
        >
          {`Your ${credit} credit with ${airline} will be applied to all eligible flights.`}
        </Text>
      </Flex>
    </Flex>
  )
}
