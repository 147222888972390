import React, { type SyntheticEvent } from 'react'
import { Box, Text, Button } from 'pcln-design-system'

type MoreCityType = {
  handleCallback: () => void
  city: string
}

function AmbiguousCityLink({ handleCallback, city }: MoreCityType) {
  return (
    <Box py={[2, null, 1]}>
      <Button
        type="button"
        variation="link"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault()
          handleCallback()
        }}
      >
        <Text fontSize={0}>Looking for a different {city}?</Text>
      </Button>
    </Box>
  )
}

export default AmbiguousCityLink
