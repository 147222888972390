import { Box, getPaletteColor } from 'pcln-design-system'
import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const StyledWrapper = styled(Box)`
  position: relative;
  padding: 16px 0;
  box-shadow: 16px 0px 0px 0px ${getPaletteColor('priceSecondary.light')},
    -16px 0px 0px 0px ${getPaletteColor('priceSecondary.light')};

  ${themeGet('mediaQueries.md')} {
    box-shadow: 24px 0px 0px 0px ${getPaletteColor('priceSecondary.light')},
      -24px 0px 0px 0px ${getPaletteColor('priceSecondary.light')};
  }
`

export default StyledWrapper
