import analytics from '@/shared-utils/analytics'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import {
  AbandonedSelection,
  PackageTypeCode,
  PriceWatchDataWithFlags,
  RecentTripSearches,
  SearchProductDataFiltered
} from './types'

const packageTypeCodeMap = {
  A: 'a',
  AH: 'ha',
  AC: 'ac',
  AHC: 'hac',
  ACH: 'hac'
} as const

function getLabelFromProductType(productType: string) {
  switch (productType) {
    case 'STAY':
      return 'hotel'
    case 'FLY':
      return 'air'
    case 'DRIVE':
      return 'car'
    default:
      return ''
  }
}

function getPriceDropLabel(savingsPercentage: number) {
  if (savingsPercentage > 0) {
    return 'price_drop'
  }
  if (savingsPercentage < 0) {
    return 'price_increase'
  }

  return 'price_unchanged'
}

function mapPackageTypeCode(code: PackageTypeCode) {
  return packageTypeCodeMap[code]
}

function getPageNameFromCategory(category?: 'PCLN_HOME' | 'HOTEL_HOME') {
  return category === ANALYTICS_CATEGORY_HOME ? 'homepage' : 'landing'
}

function getAbandonedSelectionLabel(abandonedSelection: AbandonedSelection) {
  if (abandonedSelection.productType === 'hotels') {
    switch (abandonedSelection.hotelDealType) {
      case 'RtlHotel':
        return 'hotel'
      case 'SopqHotel':
        return 'hotel express deal'
      case 'AbandonedPriceBreakersHotel':
        return 'hotel pricebreaker'
      default:
        return ''
    }
  } else if (abandonedSelection.productType === 'cars') {
    return 'car'
  }
  return ''
}

export default function fireRecentSearchCardClickEvent(
  searchItem:
    | SearchProductDataFiltered
    | RecentTripSearches
    | PriceWatchDataWithFlags,
  productType: 'STAY' | 'FLY' | 'DRIVE',
  category?: 'PCLN_HOME' | 'HOTEL_HOME'
) {
  const itemCategory =
    'isPriceWatch' in searchItem
      ? getPriceDropLabel(Number(searchItem.savingsPercentage))
      : undefined
  const ga4Item = {
    item_name: getLabelFromProductType(productType),
    item_category: itemCategory
  } as const

  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'recent_activity',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: 'search',
      page_name: getPageNameFromCategory(category),
      items: [ga4Item]
    }
  })
}

export function fireViewAllButtonClickEvent(category: string) {
  const pageName = category === ANALYTICS_CATEGORY_HOME ? 'homepage' : 'landing'
  analytics.fireGA4Event({
    event: 'display',
    attributes: {
      type: 'link',
      item_name: 'view_all_recent_activity',
      product_name: 'hotel',
      page_name: pageName
    }
  })
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      product_name: 'hotel',
      link_name: 'view_all_recent_activity',
      page_name: pageName
    }
  })
}

export function fireRecentSearchLoadEvent(
  searchItems: readonly (
    | SearchProductDataFiltered
    | RecentTripSearches
    | PriceWatchDataWithFlags
  )[],
  category?: 'PCLN_HOME' | 'HOTEL_HOME'
) {
  const ga4Items = searchItems.map(item => {
    const itemCategory =
      'isPriceWatch' in item
        ? getPriceDropLabel(Number(item.savingsPercentage))
        : undefined
    return {
      item_name: getLabelFromProductType(item.productType),
      item_category: itemCategory
    } as const
  })

  analytics.fireGA4Event({
    event: 'view_promotion',
    attributes: {
      type: 'recent_activity',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: 'search',
      page_name: getPageNameFromCategory(category),
      items: ga4Items
    }
  })
}

export function fireAbandonedSelectionLoadEvent(
  abandonedSelection: AbandonedSelection,
  category?: 'PCLN_HOME' | 'HOTEL_HOME',
  isTripGroupedSelection?: boolean
) {
  const label = getAbandonedSelectionLabel(abandonedSelection)
  analytics.fireGA4Event({
    event: 'view_promotion',
    attributes: {
      type: 'recent_activity',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: isTripGroupedSelection ? 'grouped_selection' : 'selection',
      page_name: getPageNameFromCategory(category),
      items: [{ item_name: label }]
    }
  })
}

export function fireAbandonedSelectionClickEvent(
  abandonedSelection: AbandonedSelection,
  category?: 'PCLN_HOME' | 'HOTEL_HOME',
  isTripGroupedSelection?: boolean
) {
  const label = getAbandonedSelectionLabel(abandonedSelection)
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'recent_activity',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: isTripGroupedSelection ? 'grouped_selection' : 'selection',
      page_name: getPageNameFromCategory(category),
      product_name:
        abandonedSelection.productType === 'hotels' ? 'hotel' : 'car',
      items: [{ item_name: label }]
    }
  })
}

export function fireFlightsOnContinueClickEvent() {
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'recent_activity',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: 'selection',
      page_name: 'landing',
      product_name: 'air',
      items: [{ item_name: 'air' }]
    }
  })
}

export function fireOnBookABundleCheckboxClick(packageCode: PackageTypeCode) {
  const mappedPackageCode = mapPackageTypeCode(packageCode)
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'add_on',
      creative_name: 'pick_up_where_you_left_off',
      creative_slot: 'selection',
      page_name: 'landing',
      product_name: 'air',
      bundle_flag: 'y',
      bundle_type: mappedPackageCode
    }
  })
}

export function fireFlightRecentSearchViewAllButtonClick() {
  analytics.fireGA4Event({
    event: 'display',
    attributes: {
      type: 'link',
      product_name: 'air',
      page_name: 'landing',
      item_name: 'view_all_recent_activity'
    }
  })
  analytics.fireGA4Event({
    event: 'internal_link',
    attributes: {
      product_name: 'air',
      link_name: 'view_all_recent_activity',
      page_name: 'landing'
    }
  })
}
