import React from 'react'
import { Box, Flex, Label, Checkbox, Text, Card } from 'pcln-design-system'
import styled from 'styled-components'
import { PackagesOutlineGreenOnLightGreen } from '@pcln/brand'
import { HotelOption, CarOption, FlightOption } from './utils'

type ProductOption = typeof HotelOption | typeof CarOption | typeof FlightOption

type OptionLabel =
  | typeof CarOption.label
  | typeof HotelOption.label
  | typeof FlightOption.label

type Variant1Props = {
  productOptions: [ProductOption, ...ProductOption[]]
  tripType: string
  label: string
  onSelectionChange: (
    updatedTripType: string,
    checked: boolean,
    label: OptionLabel
  ) => void
  color?: string
}

const BundleAndSaveText = styled(Text)`
  white-space: nowrap;
`
const Circle = styled(Card)`
  border: 0px;
  height: 40px;
  width: 40px;
`
export default function Variant1({
  productOptions,
  tripType,
  label,
  color = 'success.dark',
  onSelectionChange
}: Variant1Props) {
  function getUpdatedTripType(
    e: React.ChangeEvent<HTMLInputElement>,
    option: ProductOption
  ) {
    return e.target.checked
      ? `${tripType}:${option.type}`
      : tripType.replace(`:${option.type}`, '')
  }

  return (
    <Flex p={1} borderRadius="lg" height="100%" alignItems="center">
      <Flex>
        <Circle bg="highlight.light" borderRadius="3xl" mr={1}>
          <Flex
            width={1}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <PackagesOutlineGreenOnLightGreen
              size="24px"
              title="Vacation Icon"
            />
          </Flex>
        </Circle>
        <BundleAndSaveText
          pr={[1, 2]}
          color={color}
          bold
          fontSize={[0, null, null, null, 1]}
          m="auto"
          ml={0}
        >
          {label}
        </BundleAndSaveText>
      </Flex>
      <Flex style={{ flex: '1 0 auto' }} flexWrap="wrap" alignItems="center">
        {productOptions.map(option => {
          const checked = tripType.includes(option.type)
          return (
            <Box key={option.name} mt="2px" pr={[1, 2]}>
              <Label
                fontSize={0}
                htmlFor={option.id}
                color="text"
                fontWeight="normal"
              >
                <Checkbox
                  aria-label={option.label}
                  id={option.id}
                  data-testid={option.id}
                  name={option.name}
                  value={option.type}
                  checked={checked}
                  unselectedColor="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSelectionChange(
                      getUpdatedTripType(e, option),
                      e.target.checked,
                      option.label
                    )
                  }}
                />
                {` ${option.label} `}
              </Label>
            </Box>
          )
        })}
      </Flex>
    </Flex>
  )
}
