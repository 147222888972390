import React from 'react'
import { Box, Flex } from 'pcln-design-system'

type BannerHandlerType = {
  bundleAndSave: React.ReactNode
  rebookBanner: React.ReactNode
  isRebook?: boolean
  bundleAndSaveMobileWidth: Array<number | null>
  isFlightTypeMultiDestination: boolean
  showBundleAndSave: boolean
  isMobile: boolean
}

export default function FlightFormBannerHandler({
  isRebook,
  bundleAndSave,
  rebookBanner,
  bundleAndSaveMobileWidth,
  isFlightTypeMultiDestination,
  showBundleAndSave,
  isMobile
}: BannerHandlerType) {
  const showBundleAndSaveWrapper = () => showBundleAndSave && bundleAndSave
  return (
    <Flex width={!isFlightTypeMultiDestination ? [1, null, 1 / 2] : 0}>
      <Box
        width={isMobile ? bundleAndSaveMobileWidth : [0, null, 1]}
        p={2}
        mt={isMobile ? 1 : null}
      >
        {isRebook
          ? rebookBanner
          : !isFlightTypeMultiDestination && showBundleAndSaveWrapper()}
      </Box>
    </Flex>
  )
}
