import React from 'react'
import { format } from 'date-fns/format'
import { parse } from 'date-fns/parse'
import { HotelsFill, CarsFill, FlightsFill } from '@pcln/brand'
import {
  SearchProductDataFiltered,
  AbandonedHotelSelectionApiResponse,
  AbandonedCarSelectionApiResponse,
  FLY_CABIN_CLASS_TYPE,
  FLY_CABIN_CLASS,
  AbandonedPriceBreakersHotel,
  RTLHotel,
  SopqHotel,
  RecentTripSearches,
  PriceWatchData,
  RecentFlightSearches,
  FlyOriginDestinationDataFiltered,
  FlySearchData
} from './types'

const DATE_FORMAT = 'E, MMM dd'

function getDates({
  startDate,
  endDate = ''
}: {
  startDate: string
  endDate?: string | null
}) {
  const formatStartDate = startDate.split('T')[0]
  const formatEndDate = endDate?.split('T')[0]
  // ONE-WAY
  if (!formatEndDate) {
    return [
      format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd'),
      format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd')
    ]
  }
  return [
    format(parse(formatStartDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd'),
    format(parse(formatEndDate, 'yyyy-MM-dd', new Date()), 'yyyyMMdd')
  ]
}

function formatDates({ startDate = '', endDate = '' }) {
  return [
    format(parse(startDate, 'yyyyMMdd', new Date()), DATE_FORMAT),
    format(parse(endDate, 'yyyyMMdd', new Date()), DATE_FORMAT)
  ]
}

function formatTime(timeStr: string) {
  const inputFormat = 'h a'
  const parsedTime = parse(timeStr, inputFormat, new Date())
  const formattedTime = format(parsedTime, 'HH:mm')
  return formattedTime
}

function capitalizeLocation(name: string) {
  return name
    .split(' ')
    .map(item => item.charAt(0) + item.slice(1).toLocaleLowerCase())
    .join(' ')
}

function formatLocationName(name: string) {
  const location = name.split(',')
  const capitalizedLocation = capitalizeLocation(location[0])
  return `${capitalizedLocation}, ${location[1] ?? ''}`
}

function formatAirportName(
  airportName: string,
  airportCode: string,
  cityName: string
) {
  const country = cityName.split(',')[1]
  return `${capitalizeLocation(airportName)} (${airportCode}), ${country}`
}

export function mapFlyCabinClass(flyCabinClass: FLY_CABIN_CLASS_TYPE) {
  return FLY_CABIN_CLASS[flyCabinClass] ?? 'ECO'
}

// When HP_RT_SEARCHES is DEFAULT, we use getProductInfo
export default function getProductInfo(item: SearchProductDataFiltered) {
  switch (item.productType) {
    case 'FLY': {
      const [startDate, endDate] = getDates({
        startDate: item.flyOriginDestinationData[0].startDate,
        endDate: item.flyOriginDestinationData[1]?.startDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      const {
        originCityData: { cityName: originCityName },
        destinationCityData: { cityName: destinationCityName },
        originAirportData: { airportCode: originAirportCode },
        destinationAirportData: { airportCode: destinationAirportCode }
      } = item.flyOriginDestinationData[0].originDestinationData
      const mappedFlyCabinClass = mapFlyCabinClass(item.flyCabinClass)
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <FlightsFill size="24px" />
          </div>
        ),
        overline: 'Flight',
        heading: `${
          item.flyTripType === 'ROUND_TRIP'
            ? `${formatLocationName(originCityName)} - `
            : ``
        }${formatLocationName(destinationCityName)}`,
        actionText: `${formattedStartDate}${
          item.flyTripType === 'ROUND_TRIP' ? ` - ${formattedEndDate}` : ``
        }`,
        relativeUrl: `/m/fly/search/${originAirportCode}-${destinationAirportCode}-${startDate}${
          item.flyTripType === 'ROUND_TRIP'
            ? `/${destinationAirportCode}-${originAirportCode}-${endDate}`
            : ``
        }/?cabin-class=${mappedFlyCabinClass}&no-date-search=false&num-adults=1&sbsroute=slice1&search-type=10`
      }
    }
    case 'DRIVE': {
      const [startDate, endDate] = getDates({
        startDate: item.driveStartDate,
        endDate: item.driveEndDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      const {
        originAirportData: {
          airportCode: originAirportCode,
          airportName: originAirportName
        },
        originCityData: { cityName: originCityName },
        destinationAirportData: {
          airportCode: destinationAirportCode,
          airportName: destinationAirportName
        },
        destinationCityData: { cityName: destinationCityName }
      } = item.driveOriginDestinationData
      const { drivePickupTime, driveDropOffTime } = item
      const formattedDrivePickupTime = formatTime(drivePickupTime)
      const formattedDriveDropOffTime = formatTime(driveDropOffTime)
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <CarsFill size="24px" />
          </div>
        ),
        overline: 'Cars',
        heading: `${formatAirportName(
          originAirportName,
          originAirportCode,
          originCityName
        )}${
          originAirportCode !== destinationAirportCode
            ? ` - ${formatAirportName(
                destinationAirportName,
                destinationAirportCode,
                destinationCityName
              )}`
            : ''
        }`,
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: `/drive/search/r/${originAirportCode}/${destinationAirportCode}/${startDate}-${formattedDrivePickupTime}/${endDate}-${formattedDriveDropOffTime}/list`
      }
    }
    default: {
      const { cityID, cityName }: { cityID: string; cityName: string } =
        item.stayDestinationData[0].destinationCityData
      const [startDate, endDate] = getDates(item.stayDestinationData[0])
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <HotelsFill size="24px" />
          </div>
        ),
        overline: 'Hotels',
        heading: formatLocationName(cityName),
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: `relax/in/${cityID}/from/${startDate}/to/${endDate}/rooms/1/adults/2`
      }
    }
  }
}

// When HP_RT_SEARCHES is VARIANT, we use getRecentSearchDisplayProp
export function getRecentSearchDisplayProps(item: RecentTripSearches) {
  switch (item.productType) {
    case 'FLY': {
      const {
        travelStartDate,
        travelEndDate,
        listingsUrl,
        tripType,
        originAirportInfo,
        destinationAirportInfo
      } = item

      const [startDate, endDate] = getDates({
        startDate: travelStartDate,
        endDate: travelEndDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      const {
        city: originCity,
        name: originName,
        state: originState,
        country: originCountry
      } = originAirportInfo
      const {
        city: destinationCity,
        name: destinationName,
        state: destinationState,
        country: destinationCountry
      } = destinationAirportInfo
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <FlightsFill size="24px" />
          </div>
        ),
        overline: 'Flight',
        heading: `${
          tripType === 'Round Trip'
            ? `${originCity || originName}, ${originState || originCountry} - `
            : ``
        }${destinationCity || destinationName}, ${
          destinationState || destinationCountry
        }`,
        actionText: `${formattedStartDate}${
          item.tripType === 'Round Trip' ? ` - ${formattedEndDate}` : ``
        }`,
        relativeUrl: listingsUrl
      }
    }
    case 'DRIVE': {
      const {
        pickupDateTime,
        returnDateTime,
        pickupLocationName,
        returnLocationName,
        listingsUrl
      } = item
      const [startDate, endDate] = getDates({
        startDate: pickupDateTime,
        endDate: returnDateTime
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <CarsFill size="24px" />
          </div>
        ),
        overline: 'Cars',
        heading: `${pickupLocationName}${
          pickupLocationName !== returnLocationName
            ? ` - ${returnLocationName}`
            : ''
        }`,
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: listingsUrl
      }
    }
    default: {
      const { cityName, checkInDate, checkOutDate, listingsUrl } = item
      const [startDate, endDate] = getDates({
        startDate: checkInDate,
        endDate: checkOutDate
      })
      const [formattedStartDate, formattedEndDate] = formatDates({
        startDate,
        endDate
      })
      return {
        icon: (
          <div style={{ lineHeight: '0px' }}>
            <HotelsFill size="24px" />
          </div>
        ),
        overline: 'Hotels',
        heading: cityName,
        actionText: `${formattedStartDate} - ${formattedEndDate}`,
        relativeUrl: listingsUrl
      }
    }
  }
}

export function transformAbandonedCarApiResponseToProps(
  item: AbandonedCarSelectionApiResponse
) {
  const {
    carURL,
    carType,
    carExample,
    pickupLocation,
    pickupDateTime,
    returnDateTime,
    isOffAirportPickup,
    isDropOffAtDifferentLocation,
    retailTypeCarCompanyDetails,
    passengerCapacity,
    returnLocation,
    imageURL,
    genericListingsUrl
  } = item
  const parsedCheckInDate = parse(
    pickupDateTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  )
  const formattedCheckInDate = format(
    parsedCheckInDate,
    "EEE, MMM dd, yyyy 'at' h:mmaaa"
  )
  const parsedCheckOutDate = parse(
    returnDateTime,
    "yyyy-MM-dd'T'HH:mm:ss",
    new Date()
  )
  const formattedCheckOutDate = format(
    parsedCheckOutDate,
    "EEE, MMM dd, yyyy 'at' h:mmaaa"
  )
  return {
    headline: carType,
    expressDealText: carExample,
    pickupLocation,
    isDropOffAtDifferentLocation,
    returnLocation,
    specifyOffAirport: isOffAirportPickup,
    pickUpDate: formattedCheckInDate,
    dropOffDate: formattedCheckOutDate,
    numAdults: passengerCapacity.toString(),
    linkUrl: carURL,
    genericListingsUrl,
    imageSrc: imageURL,
    rcDealType: 'RETAIL',
    productType: 'cars' as const,
    brandImg: retailTypeCarCompanyDetails?.brandImageURL
      ? `${retailTypeCarCompanyDetails.brandImageURL}&opto&auto=webp&width=176`
      : undefined
  }
}

function isSopqHotel(
  hotelData: RTLHotel | SopqHotel | AbandonedPriceBreakersHotel
): hotelData is SopqHotel {
  /* eslint-disable-next-line no-underscore-dangle */
  return hotelData.__typename === 'SopqHotel'
}

function isPriceBreaker(
  hotelData: RTLHotel | SopqHotel | AbandonedPriceBreakersHotel
): hotelData is AbandonedPriceBreakersHotel {
  /* eslint-disable-next-line no-underscore-dangle */
  return hotelData.__typename === 'AbandonedPriceBreakersHotel'
}

export function transformAbandonedHotelApiResponseToProps(
  item: AbandonedHotelSelectionApiResponse
) {
  const {
    hotelURL,
    genericListingsUrl,
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfRooms
  } = item

  const parsedCheckInDate = parse(checkInDate, 'yyyy-MM-dd', new Date())
  const formattedCheckInDate = format(parsedCheckInDate, 'EEE, MMM dd, yyyy')
  const parsedCheckOutDate = parse(checkOutDate, 'yyyy-MM-dd', new Date())
  const formattedCheckOutDate = format(parsedCheckOutDate, 'EEE, MMM dd, yyyy')
  const commonDetails = {
    checkInDate: formattedCheckInDate,
    checkOutDate: formattedCheckOutDate,
    numAdults: numberOfAdults,
    numRooms: numberOfRooms,
    linkUrl: hotelURL,
    genericListingsUrl,
    productType: 'hotels' as const
  }
  const hotelData = item.hotelInfo

  if (isSopqHotel(hotelData)) {
    const { __typename, name, neighborhood, location } = hotelData

    const neighborhoodName = neighborhood?.name || ''
    const locationName = location?.name || ''
    const hotelDescription = name || ''
    return {
      ...commonDetails,
      hotelDealType: __typename,
      headline: `${hotelDescription} in ${neighborhoodName}`,
      subheadline: locationName
    }
  }

  if (isPriceBreaker(hotelData)) {
    const { __typename, pricebreakerDescription, pricebreakerLocation } =
      hotelData
    return {
      ...commonDetails,
      hotelDealType: __typename,
      headline: pricebreakerDescription,
      subheadline: pricebreakerLocation
    }
  }

  const {
    thumbnail,
    name,
    starRating,
    reviewSummaryWithEntityInput,
    neighborhood,
    __typename
  } = hotelData

  const source = thumbnail?.source
  const rating = starRating || ''
  const totalReviews = reviewSummaryWithEntityInput?.totalReviews || 0
  const overall = reviewSummaryWithEntityInput?.scoreBreakdown?.overall || ''
  const neighborhoodName = neighborhood?.name || ''

  return {
    ...commonDetails,
    hotelDealType: __typename,
    headline: name || '',
    subheadline: neighborhoodName,
    numReviews: totalReviews,
    numStars: parseInt(rating, 10),
    overallRating: parseFloat(overall),
    imageSrc: source
  }
}

export function getPriceWatchProps(item: PriceWatchData) {
  const {
    originAbbreviation,
    originCityName,
    originStateName,
    destAbbreviation,
    destCityName,
    destStateName,
    dealPrice,
    savingsPercentage,
    departDate,
    returnDate,
    tripType,
    cabinClass
  } = item
  const [startDate, endDate] = getDates({
    startDate: departDate,
    endDate: returnDate
  })
  const [formattedStartDate, formattedEndDate] = formatDates({
    startDate,
    endDate
  })
  const mappedFlyCabinClass = mapFlyCabinClass(cabinClass)
  return {
    icon: (
      <div style={{ lineHeight: '0px' }}>
        <FlightsFill size="24px" />
      </div>
    ),
    heading: `${originCityName}, ${originStateName} - ${destCityName}, ${destStateName}`,
    actionText: `${formattedStartDate} - ${formattedEndDate}`,
    relativeUrl: `/m/fly/search/${originAbbreviation}-${destAbbreviation}-${startDate}${
      tripType === 'ROUND_TRIP'
        ? `/${destAbbreviation}-${originAbbreviation}-${endDate}`
        : ``
    }/?cabin-class=${mappedFlyCabinClass}&no-date-search=false&num-adults=1&sbsroute=slice1&search-type=10`,
    priceWatch: {
      dealPrice,
      savingsPercentage
    }
  }
}

function buildPriceWatchSet(priceWatches: PriceWatchData[]) {
  const priceWatchSet = new Set()
  priceWatches.forEach(item => {
    const {
      departDate,
      returnDate,
      originAbbreviation,
      destAbbreviation,
      originCityId,
      destCityId
    } = item
    priceWatchSet.add({
      departDate,
      returnDate,
      originAbbreviation,
      destAbbreviation
    })
    priceWatchSet.add({
      departDate,
      returnDate,
      originCityId,
      destCityId
    })
  })
  return priceWatchSet
}

export function isPriceWatch(
  item: SearchProductDataFiltered | RecentTripSearches,
  priceWatches: PriceWatchData[],
  isNewRecentSearchQuery: boolean
) {
  let isRecentSearchPriceWatch = false
  if (priceWatches.length > 0 && item.productType === 'FLY') {
    const priceWatchSet = buildPriceWatchSet(priceWatches)
    if (isNewRecentSearchQuery) {
      const {
        travelStartDate,
        travelEndDate,
        originAirportInfo: { code: originCode },
        destinationAirportInfo: { code: destCode }
      } = item as RecentFlightSearches
      if (
        priceWatchSet.has({
          departDate: travelStartDate,
          returnDate: travelEndDate,
          originAbbreviation: originCode,
          destAbbreviation: destCode
        })
      ) {
        isRecentSearchPriceWatch = true
      }
    } else {
      const { flyOriginDestinationData } =
        item as FlySearchData<FlyOriginDestinationDataFiltered>
      const { startDate } = flyOriginDestinationData[0]
      const endDate = flyOriginDestinationData[1]?.startDate
      const {
        originCityData: { cityID: originCityID },
        destinationCityData: { cityID: destCityID }
      } = flyOriginDestinationData[0].originDestinationData
      if (
        priceWatchSet.has({
          departDate: startDate,
          returnDate: endDate,
          originCityId: originCityID,
          destCityId: destCityID
        })
      ) {
        isRecentSearchPriceWatch = true
      }
    }
  }
  return isRecentSearchPriceWatch
}
